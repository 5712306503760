exports.components = {
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-ourstory-js": () => import("./../../../src/pages/ourstory.js" /* webpackChunkName: "component---src-pages-ourstory-js" */),
  "component---src-pages-registry-js": () => import("./../../../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-rehearsal-js": () => import("./../../../src/pages/rehearsal.js" /* webpackChunkName: "component---src-pages-rehearsal-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-wedding-js": () => import("./../../../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */)
}

